

.events__body__image {
	width: 100%;
	height: 300px;
	overflow: hidden;
	/* border-top-left-radius: 1rem;
	border-top-right-radius: 1rem; */
	position: relative;
	border: 0px solid #fff ;
	padding: 0px;
	background-color: transparent;
}

.events__body__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	image-rendering: pixelated;
	transition: all .5s linear;
}

.event-card:hover .events__body__image img {
	transform: scale(1.1)
}

.events__body__image .bestselling {
	position: absolute;
	top: 1rem;
	left: 1rem;
	padding: .6rem 1.3rem;
	background: #fbbf24;
	color: #fff;
	border-radius: .6rem;
	font-size: 1.3rem;
	text-transform: capitalize
}

.events__body__image .new {
	position: absolute;
	top: 1rem;
	left: 1rem;
	padding: .6rem 1.3rem;
	background: #34d399;
	color: #fff;
	border-radius: .6rem;
	font-size: 1.3rem;
	text-transform: capitalize
}

.events__body__image .hot {
	position: absolute;
	top: 1rem;
	left: 1rem;
	padding: .6rem 1.3rem;
	background: #df2189;
	color: #fff;
	border-radius: .6rem;
	font-size: 1.3rem;
	text-transform: capitalize
}

.events__body__contents {
	margin: 1.5rem 0 .5rem 0
}

.events__body__contents__top {
	display: flex;
	align-items: center;
	justify-content: space-between
}

.events__body__contents__top__name {
	font-size: 1.5rem
}

.events__body__contents__top__duration {
	display: flex;
	align-items: center
}

.events__body__contents__top__duration__time {
	font-size: 1.4rem;
	margin-left: .6rem
}

.events__body__contents__price {
	margin-top: .5rem;
	font-size: 2rem;
	font-weight: 700
}

.events__body__contents__price__dollor {
	font-size: 1.4rem
}

.events__body__contents__facilities {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: .5rem
}

.events__body__contents__facilities__name {
	font-size: 1.5rem;
	text-transform: capitalize
}

.events__body__contents__button {
	margin-top: 1rem
}

.row {
	display: flex;
	flex-wrap: wrap
}

.col-1 {
	width: 8.3333333333%
}

.col-2 {
	width: 16.6666666667%
}

.col-3 {
	width: 25%
}

@media(max-width: 62em) {
	.col-3 {
		width: 33.3333333333%
	}
}

@media(max-width: 48em) {
	.col-3 {
		width: 50%
	}
}

@media(max-width: 36em) {
	.col-3 {
		width: 100%
	}
}

.col-4 {
	width: 33.3333333333%
}

@media(max-width: 62em) {
	.col-4 {
		width: 50%
	}
}

@media(max-width: 36em) {
	.col-4 {
		width: 100%
	}
}

.col-5 {
	width: 41.6666666667%
}

.col-6 {
	width: 50%
}

@media(max-width: 48em) {
	.col-6 {
		width: 100%
	}
}

.col-7 {
	width: 58.3333333333%
}

.col-8 {
	width: 66.6666666667%
}

@media(max-width: 62em) {
	.col-8 {
		width: 100%
	}
}

.col-9 {
	width: 75%
}

.col-10 {
	width: 83.3333333333%
}

.col-11 {
	width: 91.6666666667%
}

.col-12 {
	width: 100%
}

.btn-default {
	background: linear-gradient(to right, #ff4d58, #df2189);
	color: #fff;
	text-decoration: none;
	height: 4.5rem;
	line-height: 4.5rem;
	display: inline-block;
	font-size: 1.4rem;
	font-weight: 400;
	border-radius: .2rem;
	padding: 0 2rem;
	border: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase
}

@media(max-width: 36em) {
	.btn-default {
		background: linear-gradient(to right, #ff4d58, #df2189);
		color: #fff;
		text-decoration: none;
		height: 4rem;
		line-height: 4rem;
		display: inline-block;
		font-size: 1.3rem;
		font-weight: 400;
		border-radius: .2rem;
		padding: 0 2rem;
		border: none;
		outline: none;
		cursor: pointer;
		text-transform: uppercase
	}
}

.btn-dark {
	background: linear-gradient(to right, #1c1d1f, #1c1d1f);
	color: #fff;
	text-decoration: none;
	height: 4.5rem;
	line-height: 4.5rem;
	display: inline-block;
	font-size: 1.4rem;
	font-weight: 400;
	border-radius: .4rem;
	padding: 0 2rem;
	border: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: .1rem
}

@media(max-width: 36em) {
	.btn-dark {
		background: linear-gradient(to right, #1c1d1f, #1c1d1f);
		color: #fff;
		text-decoration: none;
		height: 4rem;
		line-height: 4rem;
		display: inline-block;
		font-size: 1.3rem;
		font-weight: 400;
		border-radius: .4rem;
		padding: 0 2rem;
		border: none;
		outline: none;
		cursor: pointer;
		text-transform: uppercase
	}
}

.btn-dark-sm {
	background: linear-gradient(to right, #1c1d1f, #1c1d1f);
	color: #fff;
	text-decoration: none;
	height: 4rem;
	line-height: 4rem;
	display: inline-block;
	font-size: 1.3rem;
	font-weight: 400;
	border-radius: .4rem;
	padding: 0 1.5rem;
	border: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: .1rem
}

.btn-white {
	background: linear-gradient(to right, #fff, #fff);
	color: #000;
	text-decoration: none;
	height: 3rem;
	line-height: 3rem;
	display: inline-block;
	font-size: 1.2rem;
	font-weight: 400;
	border-radius: .3rem;
	padding: 0 2rem;
	border: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: .1rem
}

.btn-pink {
	background: linear-gradient(to right, #ff4d58, #df2189);
	color: #fff;
	text-decoration: none;
	height: 3.5rem;
	line-height: 3.5rem;
	display: inline-block;
	font-size: 1.3rem;
	font-weight: 400;
	border-radius: .3rem;
	padding: 0 2rem;
	border: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: .1rem
}


.heading {
	margin-bottom: 4rem;
	font-size: 2rem;
	font-weight: 400;
	background: linear-gradient(to right, #ff4d58, #df2189);
	background-clip: text;
	color: transparent;
	transform: rotate(-2deg);
	text-transform: capitalize
}

@media(max-width: 36em) {
	.heading {
		font-size: 1.8rem
	}
}

.animation {
	transform: translateY(-10rem) scale(0);
	opacity: 0;
	transition: all .8s ease-out
}

.animate {
	transform: translateY(0) scale(1);
	opacity: 1
}

.headingAnimation {
	transform: translateY(-10rem) scale(0) rotate(-2deg);
	opacity: 0;
	transition: all .8s ease-out
}

.headingAnimate {
	transform: translateY(0) scale(1) rotate(-2deg);
	opacity: 1
}

.header {
	width: 100%;
	/*height: 100vh;
	position: relative;
	clip-path: polygon(50% 0%, 100% 0, 100% 90%, 0 100%, 0 100%, 0% 60%, 0 0)*/
}

.header__logo {
	position: absolute;
	top: 3rem;
	left: 0;
	z-index: 1
}

@media(max-width: 75em) {
	.header__logo {
		left: 5rem
	}
}

@media(max-width: 62em) {
	.header__logo {
		left: 4rem
	}
}

@media(max-width: 48em) {
	.header__logo {
		left: 2rem
	}
}

@media(max-width: 36em) {
	.header__logo {
		left: 1.5rem
	}
}

.header__logo img {
	width: 15rem;
	height: auto;
	object-fit: cover;
	cursor: pointer
}

@media(max-width: 48em) {
	.header__logo img {
		width: 13rem
	}
}

@media(max-width: 36em) {
	.header__logo img {
		width: 10rem
	}
}

.header__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden
}

.header__video img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	image-rendering: pixelated;
	animation: headerImage .5s linear
}

@keyframes headerImage {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}

.header__video video {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.header__contents {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4)
}

.header__contents__text {
	/* display: flex; */
	align-items: center;
	justify-content: center;
	height: 100vh
}

.header__contents__text__child__h1 {
	color: #fff;
	text-transform: uppercase;
	font-weight: 900;
	font-size: 4.2rem;
	line-height: 3rem;
	animation: heading 1s ease-in
}

.default-cursor {
	cursor: default !important;
}

@keyframes heading {
	0% {
		opacity: 0;
		transform: translateY(-8rem)
	}
	100% {
		opacity: 1;
		transform: translateY(0)
	}
}

@media(max-width: 62em) {
	.header__contents__text__child__h1 {
		font-size: 4rem
	}
}

@media(max-width: 48em) {
	.header__contents__text__child__h1 {
		font-size: 3rem
	}
}

@media(max-width: 36em) {
	.header__contents__text__child__h1 {
		font-size: 2rem
	}
}

.header__contents__text__child__p {
	margin-top: 1rem;
	color: #fff;
	font-size: 1.5rem;
	animation: subHeading 1s ease-out
}

@keyframes subHeading {
	0% {
		opacity: 0;
		transform: translateY(-10rem)
	}
	100% {
		opacity: 1;
		transform: translateY(0)
	}
}

@media(max-width: 48em) {
	.header__contents__text__child__p {
		margin-top: .5rem;
		font-size: 1.4rem;
		line-height: 2rem
	}
}

.header__contents__text__child__link {
	margin-top: 1rem;
	animation: headerButton 1s ease-in
}

@keyframes headerButton {
	0% {
		opacity: 0;
		transform: translateY(8rem)
	}
	100% {
		opacity: 1;
		transform: translateY(0)
	}
}