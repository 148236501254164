h1, .empty-cart {
  text-align: center;
}

.upsellcart-item {

  .item-image {
    width:86px;
    img {
      width:70px;
      height: 70px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      display: block;
      object-fit: cover;
    }
  }
  

  .btns-container {
    width: 200px;padding-top: 5px !important;
    button {
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-size: 10px;
      margin-right: 5px;
    }
    .total-price{
      width:100px;
    }

    .btn-increase {
      /*color: #fff;
      background-color: #1a1a1a; margin: 1px;*/
      margin: 1px;
      color: #6c6c6c; cursor: pointer;
    /*border: solid 1px #dfdddd;
    border-radius: 4px;*/
    }

    .btn-decrease, .btn-trash {
      /*color: #fff;
      background-color: #d9534f;*/
       margin: 1px;
       color: #d9534f; cursor: pointer;
       /*border: solid 1px #dfdddd;border-radius: 4px;*/
    }
  }
  
  .total-container {
    margin: 2rem;
    text-align: center;
  }
}


  .cart-group{
    // padding-bottom: 1rem;
    // margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:last-child{ border-bottom:none;}
  }
  .cart-page {
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    /*border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;
    background: #fff;*/
    
    .MuiCardContent-root{
      width: 100%;
      padding: 0px !important;
    }

    .total-container {
      text-align: center;
    }
    .cart-bdr{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;padding: 10px;}
  }
  .upsellcart-item {
    &:nth-child(2){border-top: 1px solid rgba(0, 0, 0, 0.1);}
    justify-content: space-between;
    .item-image {
      width:86px;
    }
    .name-price {
      width: calc(100% - 436px);
      h6{
        font-size: 14px;           
      }
      p{
        font-size: 12px;;
      }
    }
    .total-price{
      width:100px;
      text-align: right;
      h6{
        font-size: 14px;
        margin-bottom: 0.5rem;
      }
      p{
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
    .btns-container {
      width:200px;
    }
    table{border:none}
    table thead tr{background: #000; color:#fff;line-height: 25px;}
    table td{padding: 5px;}
    table{
      td{
        &:first-child{border-radius: 5px 0px 0px 5px !important;width: 170px;}
        &:last-child{border-radius: 0px 5px 5px 0px !important; text-align: center;}
      }
      &.upsell-tbl{
        thead tr{line-height: 25px;}
        }
      &.event-tbl{ 
        margin-bottom: 10px;        
      } 
      
    }
    
  }
  
  .booking-details{
    .total{background:#f2f2f2;padding: 10px;
      margin: 2px;}
      table{
        th,td{
          &:first-child{border-radius: 5px 0px 0px 5px !important;width: 150px;}
        }
      }
      .empty-outer{width:100% !important;}
  }  
  .upsell-heading{padding:0.30rem 0.25rem!important;} 
  
/* For Mobile View */
@media (max-width: 599.95px){
.cart-page{
  .MuiCardContent-root{padding: 0px;}
  table thead tr{background: #000; color:#fff;line-height: 25px;}
    table td{padding: 0px 5px;}
    table td:first-child{white-space: pre-wrap !important;}
  table.event-tbl, 
    table.upsell-tbl{
      border:none;
      font-size:12px;
    }
    table{
      &.event-tbl, &.upsell-tbl{
        td:first-child{
          min-width: 144px !important;
          //word-break: break-all;
        }
        td:nth-child(2), td:nth-child(3), td:nth-child(4){min-width: 20% !important; white-space: nowrap !important;}
      }
    }
   
    table.upsell-tbl{
      margin-top: 0px !important;
       thead tr{background: #a7a7a7; color:#fff;line-height: 25px;}
    }
    a{
      &.btn-decrease, &.btn-increase{    
        padding: 0px !important;  
        svg{
      width: 15px;
      height: 15px;
     }
    }
  }
  .upsell-heading{ font-size: 12px;} 

}



.cart-bdr{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;padding: 10px;}
.booking-details{font-size: 12px;margin-bottom: 40px !important;}
}
