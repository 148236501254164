.checkout {
  width: 50%;
  margin: 0 auto;
  text-align: center;

  h4 {
    margin: 0;
    margin-top: 1rem;
  }

  button {
    width: 200px;
    margin: 0 auto;
  }

  
}