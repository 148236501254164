.App {
  text-align: center;
}
 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
main{
  min-height: calc(100vh - 60px);
  margin-top: 56px !important;
  padding: 17px 10px;
}

/* For smaller scroll starts */
::-webkit-scrollbar {
  width: 6px; height: 6px;
}
/* Track */ ::-webkit-scrollbar-track {
  background: #F0F0F0;
}
/* Handle */ ::-webkit-scrollbar-thumb {
  background: #CDCDCD;
}
/* Handle on hover */ ::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}
/* For smaller scroll ends */

/* Loading Starts */
.svgLoader {
  animation: spin 3.5s linear infinite;
  margin: auto;
}
.loader-container{width: 100%;}
.Loader {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  justify-content: center; 
  color: #bf1914;
}

.Loader #c1, .Loader #c2 {
        animation: 
          size cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s, 
          opacity cubic-bezier(0.3, 0.61, 0.355, 1) 1.8s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        stroke-opacity: 1;
      }
      @keyframes size{
        0% { transform: scale(0); }
        100% { transform: scale(1); }
      }
      @keyframes opacity {
        0% { stroke-opacity: 1; }
        100% { stroke-opacity: 0; }
      }
      .Loader #c2 { animation-delay: -.9s; }
/*.divLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.divLoader svg path{fill:#bf1914 !important;}*/
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Loading Ends */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS Starts */

body {   
  background: #f6f7fb;
  letter-spacing: 0px;
  font-size:14px;
  font-family: 'Roboto', sans-serif;
}
html, body, #root{
  height: 100%;
}

@font-face {
  font-family: 'OstrichSans-Medium';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/OstrichSans-Medium.eot') format('eot'), /*IE9 Compat Modes */
       url('fonts/OstrichSans-Medium.otf') format('otf'), /* IE6-IE8 */
       url('fonts/OstrichSans-Medium.svg') format('svg'), 
       url('fonts/OstrichSans-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/OstrichSans-Medium.woff2')  format('woff2'), /* Super Modern Browsers */
       url('fonts/OstrichSans-Medium.ttf')  format('ttf') /* Safari, Android, iOS */
}
button, button:focus{
outline: none;  
}
/* Login CSS Starts */
.login-bg{
  max-width: 520px;
  margin: 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
}
.loginLogo{
height: 121px !important;
width: 212px !important;  
margin-top: 20px;
position: absolute !important;
}
.loginTop{
height: 121px;
width: 100%; 
background-color: #bf1914;
border-radius: 20px 20px 0px 0px; 
position: relative;
bottom: -20px;
z-index: -1;
}
.button span{
  text-transform: inherit !important;
}
button .MuiButton-label{padding: 1px 0px 1px 0px;}
.btn-redoutline {
border: solid 1px #c4201b !important; 
 color: #c4201b !important;
outline: 0px !important;text-transform: capitalize !important;
}
.btn-redoutline:hover{background-color: rgb(255 231 231) !important;}
.btn-redoutline.MuiButton-text{padding: 5px 8px !important;}
.link-gray{
  color:rgba(0, 0, 0, 0.87) !important;
  padding: 1rem 1.5rem !important;
  display: block;
  background: #e0e0e0 !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  border:none !important;
}
.link-gray:hover{
  background: #d5d5d5 !important;
  text-decoration: none !important;
}
.link-red{
  color:rgb(255,255,255) !important;
  padding: 1rem 1.5rem !important;
  display: block;
  background: rgb(189,28,30) !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  border:none !important;
}
.link-red:hover{
  color:rgb(255,255,255) !important;
  background: rgb(207,30,33) !important;
  text-decoration: none !important;
}
.link-black{
  color:rgb(255,255,255) !important;
  padding: 1rem 1.5rem !important;
  display: block;
  background: rgb(0,0,0) !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  border:none !important;
}
.link-black:hover{
  color:rgb(255,255,255) !important;
  background: rgb(49, 49, 49) !important;
  text-decoration: none !important;
}
.btn-sm{
  padding:.5rem 1rem !important;
  font-size: 12px !important;
}
/*.page-heading .title {
  font-family: OstrichSans-Medium !important;
  color:#343434 !important;
  padding: 5px 0px;
}*/
h5, .title{  
 /* .MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-root,  */
font-family: OstrichSans-Medium !important;
color:#343434 !important;
font-size: 20px !important;
letter-spacing: 2px !important;
text-transform: uppercase;
font-weight: 600 !important;

}
.breadcrumbs{
  margin: 0px !important;
  padding: 12px 16px !important;
  display: flex !important;
}
.MuiDialog-paper button svg{
/* color:#ffffff !important; */
}
hr.MuiDivider-root{
  background: rgba(0, 0, 0, 0.1);
}

/*.link{
text-transform: capitalize;
font-weight: normal;
text-decoration: underline;
font-size: 14px;  
}*/

/* Header CSS Starts */
nav li:last-child p{
color:#969494 !important;
}
/* header{
box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 8%), 0px 1px 10px 0px rgb(0 0 0 / 5%) !important;
background: #bf1914 !important;
color:#646567 !important;

} */
.flex-end{
display: flex;
justify-content: flex-end;
}
/* SideBar CSS Starts */

.logoHeader{
height:38px !important;
width: 88px !important; 
border-radius: 0px !important;
position: absolute !important;
top: 8px;
}
.logoHeaderBg{
background: #000000;
padding: 32px 10px 32px 20px;
}
.toolbar{
background: #0d0d0d;
height: 100%;
color: #b8b8b8;
}
.toolbar svg{
color: #b8b8b8;
}
.list-item span, .MuiMenuItem-root{
font-size: 14px !important;
}
/*.active svg{
color: #c1211c;
}*/
.active .active-bdr{
border-left: solid 5px #c1211c;
}
.text-red{
color: #bf1914 !important;
}
.icon-minwidth{
min-width:40px !important;
} 

/* Style for User Management */

.outlined-primary {
color: #3f51b5 !important;
border: 1px solid rgba(63, 81, 181, 0.5)!important;
}
table{
margin-top: 0px !important;
border: solid 1px rgba(224, 224, 224, 1);
}
table thead th{
color: #666 !important;
background-color: #e8e8e8 !important;
padding: 0.30rem 0.25rem!important;
}
table tbody td{padding: 0.30rem 0.25rem!important;}
table tbody td:last-child button {padding: 5px !important;}
table tbody td:last-child button svg{width: 20px !important;
  height: 20px !important;}
table tbody td .company-name{
border-radius: 3px;
  border: solid 1px #ccc;
  width: max-content;
  padding: 2px 6px;
  display: inline-block;
  margin: 0px 2px;
}
table td, table th{
vertical-align: middle !important;
}
table tr th:last-child, table tr td:last-child{
width: 150px;
  text-align: center;
  white-space: nowrap;
}
.smaller-input{
width: 100% !important;
}
.create-user .MuiFormControl-root{
width: 100% !important;
font-size: 14px;
}
.smaller-input label  {
font-size: 14px;
padding: 2px 5px;
}
.fSize-12{
font-size: 12px;
}

.MuiButtonGroup-vertical button{
border: none !important;
}
.MuiButtonGroup-vertical span{
justify-content: left !important;
}
select:disabled svg{
display: none !important;
}
  .event-list li{
    padding: 5px 15px;
  }

.bg-profile{
background: #fae2e2;
}
.bg-profile img{
  border-radius: 200px;
}
footer .bottom-link{
font-size: 12px;
line-height: 45px;
}
footer{
width: 100%; position: relative; bottom: 0px;
}
footer h6{
font-size: 14px !important;
}
footer p{
font-size: 12px !important;
}
footer .MuiButtonGroup-grouped, footer .MuiBottomNavigationAction-label{
font-size: 12px !important;
  padding: 0px;
}
.logoFooter{
width: auto !important;
height: auto !important;
border-radius: 0px !important;
margin:0 5px !important;
}
.logoFooter img{
width: 70px !important; 
border-radius: 0px !important;
position: relative !important;
display: inline-block !important;
}
.footer-bottom{
display: flex;
background: #000;
color: #fff;
justify-content: center;
align-items: center;
padding: 5px;
}
footer .MuiPaper-outlined{
border:none;
border-radius: 0;
}
.MuiTablePagination-spacer{flex: inherit !important;}
.MuiTablePagination-root{display: block !important;}
.MuiTablePagination-selectRoot{
margin-right: auto !important;
}
/*.MuiMenu-paper{
margin-top: 49px !important;
}*/
.MuiDrawer-paper{
border: none !important;
}
.MuiTablePagination-root .MuiToolbar-gutters{
padding: 0px 5px !important;
}
.MuiList-padding{
padding: 0px !important;
}
.MuiTab-root.Mui-selected{
  background: #f3dddc;
}
.MuiTab-root h6{font-size: 16px !important;}
.truncate {white-space: nowrap;
max-width: 200px;
overflow: hidden;
text-overflow:ellipsis;}
.truncate[data-title] {
font-size: 18px;
position: relative;
cursor: help;
}
.truncate[data-title]:hover::before {
content: attr(data-title);
position: absolute;
bottom: -46px;
padding: 10px;
background: #000;
color: #fff;
font-size: 14px;
white-space: nowrap;
}
.form-control{
width:100% !important;
}
.choosefile{
border: solid 1px #ccc;
  padding: 13px 20px;
  border-radius: 4px;
  width: 100%; cursor: pointer;
}
.choosefile input{font-size: 14px; }
.upload-label{
position: absolute;
  float: left; 
  left: 23px;
  top: 0px;
  background: #fff;
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px 7px;
}

.list-item:hover{
text-decoration: none;
  /* background-color: rgb(220 170 170 / 4%); */
}
.MuiListItem-button:hover{background-color: rgb(220 170 170 / 4%) !important;transition: 'all .5s linear',}
.event-card{
position: relative;
box-shadow: none !important;
border:1px solid rgba(0, 0, 0, 0.1);
}
.event-card:hover{
box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3) !important;
}
.event-bg{
background: rgba(0,0,0,.035);
}
.event-title h4{
font-size: 16px !important;
text-transform: capitalize;   
margin-bottom: 0px;
}
.event-title h6{
font-size: 14px !important;
color: #bf1914 !important;
margin: 8px 0px 0px 0px;
line-height: 1;
}
.event-title p{
font-size: 12px !important;
}
.event-action{
display: none !important;
transition: all 5s linear;
}
.event-card:hover .event-action{
position: absolute;
top: 0;
border: 0;
left: 0;
right: 0;
margin: auto;
display: flex !important;
justify-content: center;
align-items: center;
height: calc(100% - 81px);
z-index: 10;
background: rgba(0, 0, 0, 0.3);
transition: all 5s linear;
animation: fadein .5s ease-in;
}

@keyframes fadein {
from { opacity: 0.7; }
to { opacity: 1; }
}

.event-action a:hover{
text-decoration: none;
}
.event-details h2{
font-size: 24x !important;
}
.event-details h3{
font-size: 24px;
}
.event-details h4{
font-size: 22px !important;
  color: #646161 !important;
  text-transform: uppercase; margin-bottom: 0px;
}
.event-details img:hover{
transition: none !important;
}
.no-event{
width: 400px !important;
height: 300px !important;
margin:auto;
}
.details-img{
  max-width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  border: 0px solid #fff;
  padding: 0px;
  background-color: transparent;
}
.details-img img{
width: 100%;
  height: 100%;
  object-fit: cover;
transition: none !important;
}
.details-img img:hover{
transform: none !important;
}
.event-info{
box-shadow: none !important;
border: 1px solid rgba(0, 0, 0, 0.1);
}
.event-dates{
box-shadow: none !important;
}
.event-content{
padding: 0 !important;
}

.ed-date-time{
background: #323232;
color: #fff;
display: flex;  
width: 130px;
flex-direction: column;
justify-content: space-between;border-radius: 5px 0px 0px 5px;
}
.ed-date-time .date{
text-align: center;
margin-bottom: 0;  
padding:.5rem;
line-height: 4;
font-size: 12px;
}
.ed-date-time .date{
display: block;
font-size: 14px;
}
.ed-date-time .time{
font-size: 12px;
text-align: center;
margin-bottom: 0;  
padding: .15rem .5rem;
background: #000;line-height: 35px;
text-transform: uppercase;border-radius: 0px 0px 0px 5px;
}
.ticket-details{
width: calc(100% - 100px);color: #000;
margin:0 !important;border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 0px 5px 5px 0px;
}
.ticket-details h6{
    font-size: 14px !important;font-weight: normal;
}
.ticket-details button.btn-action{color:#fff !important;}
.select-box{
border-radius: 5px;
margin-top: 5px;
padding: 3px 7px;
border:1px solid rgba(0,0,0,0.3);font-size: 14px;
background: #fff;height: 26px;
}
.select-box.cal-time{width:100%; height: 35px;border:solid 1px #ccc;}
.btn-action {
color: #fff !important;
font-weight: normal !important;
padding: .5rem;
display: inline-block;
background: rgb(189,28,30) !important;
text-decoration: none;
text-transform: uppercase;
border-radius: 6px;
font-size: 16px;
border: none !important;
margin: .25rem;
}
.btn-action:hover{
color:rgb(255,255,255) !important;
background: rgb(207,30,33) !important;
text-decoration: none !important;
}
.btn-gray{
  color:rgba(0, 0, 0, 0.87) !important;
  padding: .5rem;
  display: block;
  background: #e0e0e0 !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px;
  /* font-size: 16px !important; */
  border:none !important;
  font-weight: normal !important;
}
.btn-gray:hover{
  background: #d5d5d5 !important;
  text-decoration: none !important;
}
.btn-action.disabled{ 
  background: rgb(0,0,0,0.2) !important;color: #999 !important;border: none !important;
  pointer-events: none;
}
.btn-action.disabled:hover{
  background: rgb(0,0,0,0.2) !important;
}
.btn-action.bg-orange{
  background: #ffa500 !important;border: none !important;
}
.btn-action.bg-orange:hover{
  background: #ed9e0c !important;
}
.btn-action.bg-green{
  background: #28a745!important;border: none !important; 
}
.btn-action.bg-green:hover{
  background: rgb(55 187 85) !important;
}
.ed-title{
background: rgb(189,28,30);
position: absolute;
bottom: 0;
padding: .5rem 1rem;
}
.ed-title h4{
color: #fff !important;
font-size: 24px !important;
margin-bottom: 0px;
}
.ed-title h6{
color:#fff !important;
font-size: 16px !important;
margin-bottom: 0;
font-weight: 400;
}
.ed-para{
padding: .5rem 1rem;    
font-size: 14px !important;
}
/*.event-tab .MuiTabs-flexContainer{
justify-content: center;
margin: 2rem 1rem;
}
.event-tab .btn{
background: #fff;
border:1px solid #000;
margin:0 .5rem;
}
*/
.event-tab {margin-bottom: 5px;color: #000;}
.event-tab .MuiTab-root{text-transform: capitalize;}
.event-tab .MuiTabs-indicator{
background: rgb(189,28,30);
}
.event-tab button.MuiTab-root.Mui-selected{background:transparent !important;}
.event-tab .MuiTab-root.Mui-selected{
  background: #fff;
  color:rgb(189,28,30);
  }
.listing-badge.now-open {
background-color: #54ba1d;
}
.listing-badge {
background-color: #333;
float: left;
position: absolute;
transform: rotate(
45deg);
right: -75px;
top: -4px;
text-align: center;
width: 162px;
font-size: 10.5px;
margin: 0px 5px;
z-index: 999;
color: #fff;
font-weight: 500;
line-height: 30px;
padding: 0px;
}
.notification .MuiMenu-paper{
top:50px !important;
}

header{
background: #000000 !important;
box-shadow: none !important;
border-radius: 5px !important;
}

.searchContainer{
float: left;
  border: solid 1px #7e7c7c;
  padding: 3px;
  margin: 8px;
  margin-left: 50px;
  font-size: 12px !important;
  background: #e7e7e7;
}
.searchContainer .MuiInput-root{font-size: 12px !important;}

.searchContainer label{
font-size: 14px !important;
}

.article-container {
border: 1px solid #000000;
overflow: hidden;
position: relative;
}

.article-img-holder {
width: 100%;
height: 100%;  
background-position: center;
background-size: cover;
background-repeat: no-repeat;
transition: all 1s;
}

.article-img-holder:hover {
transform: scale(1.2);
}
.dropzone p{
font-size: 12px; margin: 10px;
}
.dropzone svg{
width: 26px; height: 26px;
}
.dropzone .MuiDropzoneArea-root{
max-height: 100px;
min-height: 70px;
}
.dropzone .MuiDropzoneArea-root .MuiGrid-item{padding: initial;}
.breadcrumbs-level2{
margin: 0px 8px 8px 16px !important;
}
.breadcrumbs-level2 li, .breadcrumbs-level2 p, .create-user label{
font-size: 14px;
}
.breadcrumbs-level2 li a:hover{
color: #bf1914 !important;
}
table td button svg{
width: 18px;
height: 18px;
}
.control-sm [role="button"]{
padding: 10px 10px;
  font-size: 14px;
}
.cart-align{ 
/* position: absolute;
right: 0px;
top: 10px;
color: #FFE; */
height: 100%;
align-items: center;
}

.icon-medium{height: 24; width: 24; display:inherit;}
.event-title{
font-size: 1rem;
line-height: 1.25;}

.success-outline{
  background-color: transparent;
  border: 1px solid rgba(46, 125, 50, 0.7) !important;
  color: rgb(46, 125, 50) !important;
  font-size: 12px !important;
}
.warning-outline{
  background-color: transparent;
  border: 1px solid rgba(211, 47, 47, 0.7) !important;
  color: rgb(211, 47, 47) !important;
  font-size: 12px !important;
}
.success-filled {
  background-color: rgb(46, 125, 50) !important;;
  border: 1px solid rgba(46, 125, 50, 0.7) !important;
  color: white !important;
  font-size: 12px !important;
}
.warning-filled {
  background-color: rgb(211, 47, 47) !important;;
  border: 1px solid rgba(211, 47, 47, 0.7) !important;
  color: white !important;
  font-size: 12px !important;
}

.unavailable-event {
  background-color: rgb(189, 189, 189) !important;
  border: 1px solid rgba(158, 158, 158, 0.7) !important; 
  color: rgb(97, 97, 97) !important;
  font-size: 12px !important;
  opacity: 0.6; 
  pointer-events: none; 
}

.alert-filled {
  background-color: #ff9800 !important;
  border: 1px solid #ff9800 !important;
  color: white !important;
  font-size: 12px !important;
}
.sold-ticket{
  color: #4caf50;
  font-weight: 600;
  background: rgba(76,175,80,0.1);
  padding: 2px;
  border-radius: 3px;
  margin-right: 8px;
}
/*.upsell table tr th:first-child, .upsell table tr td:first-child{
  width: 160px;
    text-align: left;
    white-space: nowrap;
}
.upsell table tr th:last-child, .upsell table tr td:last-child{
  width: auto;
    text-align: center;
    white-space: nowrap;
}*/
.MuiTypography-subtitle1{
  line-height: 18px !important;
}
.upsell-modal{
  width: 600px !important;
}

.upsell-modal h4{font-size: 16px !important;  font-weight:600;}
.upsell-modal h6{font-size: 14px !important;}
.upsell-modal .ticket-details{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 5px;}
.scrollbar {
  height: 200px;
  overflow: auto;
  position: relative;
  scrollbar-width: thin;
  border-radius: 0px;
  }
  .scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5; }
  .scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;

  background-color: #d1cccc }
  .scrollbar {
  scrollbar-color: #d1cccc #F5F5F5;
  }
  select{
    color:#000000;
    outline: none;
  }
  select option{
    color:#000;
  }
  
    .booking-bg{
      width: 50%;
      margin: 0px auto;
    }
    .booking-bg img{
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      object-fit: cover;
      width: 150px;
    }
    .booking-bg p{
      margin: 2px 0px 8px 0px;
    }
    .promo-image{
      width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    border: 0px solid #fff;
    padding: 0px;
    background-color: transparent;
    }
    .qr-detailsbg {
      width: 20%;
      margin: 10px auto;
    }
    .qr-detailsbg .reader{
      width:180px; height: 180px;
    }
    .qr-detailsbg section div{
      border:0px !important;
    }
    .qr-content{
      padding: 0 !important;
      display: flex;  
      }
    .qr-details{
      width: calc(100% - 100px);
      margin:0 !important;
      }
     
      .qr-content .ticket-details h5{
        font-size: 16px !important;
      }
      .qr-content .ticket-details h6{
        font-size: 14px !important;
        font-weight: 400 !important;
      }
      .qr-content .ticket-details .total-bg{
        background: #fbf8f8;
      }
      .qr-content .ticket-details strong{
        float: right;
      }
      .qr-content .ticket-details .padding-push{
        padding-left:50px;
      }
      .qr-content .ticket-details p{
        margin:0px;
      }
      .qr-content .ticket-details .total .cost{
        font-size: 16px;
        color: #cf1e21;
      }
      .is-disabled
      {
        pointer-events: none;
        opacity: 0.7;
      }
      button.close-preview{
        float: right;
        margin: 0px !important;
      }
      button.close-preview svg{
        color: #646464 !important;
      }
     .date-container .css-169iwlq-MuiCalendarPicker-root{
      width: 100%;
      border: solid 1px #ccc;
      margin: 13px 0px;
      border-radius: 5px;overflow: hidden;
     }
     .date-container .css-1xhj18k .css-epd502{width: 370px;margin: inherit;}
     .date-container .css-epd502{width: 100% !important;}
     .date-container .css-1w13o7u-MuiTypography-root{font-size: 16px;padding: 12px;}
     .date-container .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer{overflow-y: hidden;}
     .date-container .Mui-selected{background-color: #39529a !important;}
     .date-container button.MuiIconButton-edgeStart svg,
     .date-container button.MuiIconButton-edgeEnd svg{border: solid 2px #39529a;border-radius: 20px;}
     .date-container button.MuiIconButton-edgeStart svg path,
     .date-container button.MuiIconButton-edgeEnd svg path{fill: #39529a;}     
      .date-container .MuiBadge-anchorOriginTopRightCircular::before{border: 1px solid #39529a;
        border-radius: 20px;
        background-image: url("assests/tick_mark.png") !important;
        background-position: 15px 13px;
        content: ""; 
        color: #39529a; 
        position: absolute;
        top: -17px;
        width: 14px;
        right: -21px;
        font-size: 10px;
        height: 14px;
        text-indent: 3px;
        margin: 20px !important;
        padding: 0px;
        vertical-align: middle;
        line-height: 12px;}
        .date-container button.MuiPickersDay-root{padding: 12px;}
        .date-container .css-188yni7-MuiPickersDay-root{padding: 12px;}
        .date-container .css-1dozdou{padding-right: 25px;}
        .date-container .css-epd502{max-height: 320px;}
        .date-container .css-4l7j15{min-width:auto !important;}
     /*.date-container svg.MuiSvgIcon-root{border: solid 1px #ccc;border-radius: 20px;}*/
     .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
      background-color: #000000 !important;
     }
     .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
      background-color: #000000 !important;
     }
     .MuiPickersStaticWrapper-staticWrapperRoot, .MuiPickersBasePicker-pickerView{
       min-width: auto !important;max-width: 100% !important;justify-content: flex-start !important;
     }
     .MuiPickersToolbarText-toolbarTxt{
      font-size: 20px !important;
     }
     .MuiPickersToolbar-toolbar{
      height: 50px !important;
     }
     .MuiDialogContent-root{padding: 8px !important;}
     .MuiCheckbox-colorSecondary.Mui-checked{color: #bf1914 !important;}
      .MuiCheckbox-root{color:#bf1914 !important;}
     .remove-margin{width: auto !important;margin: auto !important;}
     .ticket-details button.btn-action{width: 125px;margin: 0.25rem !important;text-transform: capitalize !important;}
     .ticket-details button.btn-redoutline {background:#fff !important; border: solid 1px #bf1914 !important; color: #c4201b !important;}
     button{text-transform: capitalize !important;}
     .empty-outer{width: fit-content;
      margin: 5% auto !important;
      background: #f6f6f6;
      border: solid 1px #f1eeee; border-radius: 5px;}
      .empty-outer li{padding-top: 20px;padding-bottom: 20px;}
     .cart-empty{width: auto !important;
      height: auto !important;
      margin: auto; 
      border-radius: 0px !important;padding-right: 10px;}
      .cart-empty img{width: auto;}
      .eventlist-holder{width: 100%;
        max-height: 250px;}
      .eventlist-holder img{
        /* width: auto !important; */
        max-width: 100% !important;
        max-height: 100% !important;}
      .MuiCheckbox-colorSecondary.Mui-checked{color: #bf1914 !important;}
      .MuiCheckbox-root{color:#bf1914 !important;}
      .terms-checkbox{align-items: flex-start !important;}
      .terms-checkbox span.terms-text{font-size: 12px;
        padding-top: 0px;
        height: 73px;
        display: initial;}
        .terms-checkbox span.terms-text p b{word-break: break-all;}
      
      /*.terms-checkbox > span{
        min-height: 100px;
    display: block;
    padding-top: 0px;
    max-height: 200px;}*/

/* The Modal Index starts (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 20px; /* Location of the box */
  left: 0;  
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Dialog */
.dialog-content{
  padding: 0px;
 /* max-height: calc(100vh - 140px) ;*/
}
.dialog-title {
  background: #000000 !important;
  color: #ffffff;
  padding: 0px 10px !important;
}
/* Dialog */


/* searchBar */
.searchbar{
  border-radius: 6px;
  width: 400px;
}
.searchbar .select{  
  color: rgb(245 234 234 / 100%) !important;
  margin-left: 0;
  width: 200px;
  border-radius: 6px 0 0 6px;
  background: none;
  font-size: 14px;  
  border:solid 1px rgb(209 196 195 / 50%);
  padding: 0px 5px;
  height: calc(1.7em + .5rem + 2px);
}
.searchbar button{
    color: #ffffff;
    background: #bf1914;
    border-radius: 0 6px 6px 0;
    min-width: auto;
}
.searchbar button:hover{
  background: #cf1e21;
}
.searchbar .search-area{
  color: rgb(245 234 234 / 100%) !important;
  border-top: solid 1px rgb(209 196 195 / 50%);
  border-bottom: solid 1px rgb(209 196 195 / 50%);
  width: 100%;
}

.searchbar input{
    color: #fff;
    width: 100%;
    font-size: 14px;
    background: transparent;
    padding: 5px;
    border: none;
    outline: none;
}

   
.add-to-cart-container {
  background-color: '#FFFFFF'; 
  border: 1px solid #EDEBF9; 
  border-radius: 8px;
  padding: 20px 24px !important;
  margin: 20px 20px 20px 20px;
};

/* Cart Widget Page */

/* For Small Mobile View */
@media only screen and (max-width:320px){
  .searchbar .select{
    width: 85px !important;
  }
  .page-heading{display: inline-block !important;}
  .page-heading .title{margin-left: 8px;}
}

/* For Mobile View */
      @media (max-width: 599.95px){ 
        .title {
          font-size: 16px !important;
        }   
        .ticket-details h6{
          font-size: 12px !important;
          font-weight: normal;white-space: nowrap;
        }
          
      .ed-date-time {
          width: 120px;
      }   
      .ed-date-time .date{
        line-height: 8;
      }
      .qr-detailsbg{width: auto;}
      .qr-content .ticket-details .padding-push{
        padding-left:0px;
      }
      
       .no-event{width: 180px !important;}
       .upsell-modal{
        width: auto !important;
      }

        .empty-outer {width: 100%;margin: 20% auto !important;}
        .date-container{
          margin-top: 0px !important;padding: 0px !important;
         }
        .date-container .css-4l7j15{
          min-width:auto !important;
        }
        .searchbar{
          top: 64px;
          left: 0px;
          transition: all .5s linear; 
          left: -15px;
          width: 100%;
        }
        .searchbar .select{
          width: 100px;
          font-size: 12px !important; 
        }
        .searchbar input{
          font-size: 12px !important; 
          padding: 6px;
        }
        

  }  
  
    /* For SM View */
    @media(max-width: 768px){
   
      .add-to-cart-container {
        border: 1px solid #EDEBF9; 
        border-radius: 8px;
        padding: 16px !important;
        margin: 16px;
      };


      .ed-date-time .date{
        font-size: 12px;
      }
     /* .date-container .css-1w13o7u-MuiTypography-root,
      .date-container button.MuiPickersDay-root,
      .date-container .css-188yni7-MuiPickersDay-root {padding: 20px;}*/
    }
    /* @media(min-width: 960px){ */
      /*.empty-outer {width: 50%;}*/
    /* } */

/* For only firefox starts */
@-moz-document url-prefix() {
  h5, .title {
    font-family: "Helvetica", sans-serif !important;
    letter-spacing: 0px !important;
    font-weight: normal !important;
    font-size: 16px !important;
    padding: 4px 0px;
  }
} 
/* For only firefox ends */


.ticket-only-description {
  font-size: 12px;
  line-height: 16px;
}

.event-date-subtitle {
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin: 5px;
}
.MuiAlert-root.custom-message {
  padding: 2px;
}

.MuiAlert-root.custom-message .MuiAlert-message {
  padding: 5px;
}

.occurrence-widgetpage .event-dates {
  border-top: none;
}

.all-occurrences-in-day {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;



}
.occurrence-widgetpage .MuiChip-root {
  border-radius: 22px;
  height: auto;
}

.all-occurrences-in-day .MuiChip-sizeSmall {
  padding: 5px;
}

.all-occurrences-in-day .MuiChip-label.MuiChip-labelSmall {
  padding: 6px;
  font-size: 20px;
  height: auto;
  line-height: 16px;
}

.greeting-grouping {
  color: var(--global-primary-text-color);
}

.partySizeCopy {
  color: var(--global-primary-text-color);
  font-size: 20px;
  line-height: 30px;  
}

#disabled-btn {
  background: rgb(0,0,0,0.2) !important;
  color: #999 !important;
  border: none !important;
  pointer-events: none;
}

.terms-link, .terms-link:hover {
  color: #bf1914;
}

.img-responsive {
  max-width: 100%;
}
.MuiCalendarPicker-root .PrivatePickersSlideTransition-root.css-dhopo2 {
  min-height: 225px;
}