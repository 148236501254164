.success-page{
  margin: 3rem 0;
  box-shadow: none;
  border: 10px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: Block;
  .success-msg{
    padding: 2rem;
    text-align: center; 
    h1{
      margin: 2rem 0;
      font-weight: 400;
    }  
    .download-link{
      display: flex;
      justify-content: center;
      /*a{
        width: 25%;
      }*/
    }
} 
}
.ticket-info{
  width: 300px;
  margin: 0 auto 2rem auto;  
  display: block;
  background: #000;
  color:#fff;
  font-size: 14px;
  text-align: left;
  .MuiBox-root{
    padding:.25rem .5rem;
    width: 100%;
  }  
  .header{
    padding: .5rem;
    position: relative;
    margin-bottom: 1rem;
    background: rgb(207,30,33);
    border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
    &::before{
      content: '';
      position: absolute;
      width:30px;
      height: 30px;
      border-radius: 30px;
      background: #fff;
      left: -20px;
      bottom: -16px;
    }
    &::after{
      content: '';
      position: absolute;
      width:30px;
      height: 30px;
      border-radius: 30px;
      background: #fff;
      right: -20px;
      bottom: -16px;
    }
    h5{
      margin-bottom: 0;
      color:#fff !important;
    }
    p{
      margin-bottom: 0;
      font-size: 12px;
    }
  }
  .footer{
    margin-top: 1rem;
    padding: .5rem;
    border:1px solid rgba(255,255,255,0.3);
    h6{
      font-size: 14px;
      margin-bottom: 0;
    }
    p{
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}

/* For Mobile View */
@media (max-width: 599.95px){ 
  .success-page {
    .success-msg {
      padding: 5px;
      .ticket-info {width: 100%;} 
  }
}
}