h1, .empty-cart {
  text-align: center;
}

.btn-trash {
   color: #d9534f; cursor: pointer;
}

.cart-item {

  .item-image {
    width:86px;
    img {
      width:70px;
      height: 70px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      display: block;
      object-fit: cover;
    }
  }
  

  .btns-container {
    width: 200px;padding-top: 5px !important;
    button {
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-size: 10px;
      margin-right: 5px;
    }
    .total-price{
      width:100px;
    }

    .btn-increase {
      /*color: #fff;
      background-color: #1a1a1a; margin: 1px;*/
      margin: 1px;
      color: #6c6c6c; cursor: pointer;
    /*border: solid 1px #dfdddd;
    border-radius: 4px;*/
    }

    .btn-decrease, .btn-trash {
      /*color: #fff;
      background-color: #d9534f;*/
       margin: 1px;
       color: #d9534f; cursor: pointer;
       /*border: solid 1px #dfdddd;border-radius: 4px;*/
    }
  }
  
  .total-container {
    margin: 2rem;
    text-align: center;
  }
}


  .cart-group{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:last-child{ border-bottom:none;}
  }
  .cart-page {
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    /*border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;
    background: #fff;*/
    
    .MuiCardContent-root{
      width: 100%;
      padding: 0px !important;
    }

    .total-container {
      text-align: center;
    }
    .cart-bdr{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;padding: 10px;}
  }
  .cart-item {
    justify-content: space-between;
    .item-image {
      width:86px;
    }
    .name-price {
      width: calc(100% - 436px);
      h6{
        font-size: 14px;           
      }
      p{
        font-size: 12px;;
      }
    }
    .total-price{
      width:100px;
      text-align: right;
      h6{
        font-size: 14px;
        margin-bottom: 0.5rem;
      }
      p{
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .btns-container {
      width:200px;
    }
    table{border:none}
    table thead tr td{background: #000; color:#fff;line-height: 25px;
      font-size: 14px !important;}
    table thead td{
      padding: 8px !important;}
      table tbody td{
        font-size: 12px !important;
        padding: 8px !important;}
    table{
      td{
        &:first-child{border-radius: 5px 0px 0px 5px !important;width: 30%;
          // white-space: break-spaces;
          // word-break: break-all;
        }
        &:nth-child(2){width: 20%;}
        &:nth-child(3){width: 20%;}
        &:last-child{border-radius: 0px 5px 5px 0px !important; text-align: right;min-width: 70px; }
      }
      &.upsell-tbl{
        thead tr{line-height: 25px;}
        tbody tr td h6{ text-align: start;}
        }
      &.event-tbl{
        margin-bottom: 10px;        
      } 
      td span.headIcon{
        display: initial; 
        width:auto;
       }
       td span.bodyIcon{
         display: inline-flex; 
         width:auto;top: 2px;
         position: relative;
        }
      td p.icon-aligns{
        padding:14px 4px 0px 4px;
      }
      td p.iconupsell-align{
        padding:4px 4px 0px 4px;
      }
      small{white-space: nowrap !important;}     
    }
        
  }
  .cart-item-breakdown {
    justify-content: space-between;
    margin-top: 5px; 
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    .item-image {
      width:86px;
    }
    .name-price {padding:5px 0px 0px 5px;
      width: calc(100% - 436px);
      h6{
        font-size: 14px;  margin-bottom: 10px; 
        line-height: 20px;          
      }  
      h4{
        font-size: 16px;
        color:rgb(207,30,33);
        font-weight: 600;
      }   
    }
    .total-price{
      // width:100px;
      text-align: right;   
      padding: 5px 0px 0px 5px; 
      p{
        font-weight: 600;
        margin-bottom: 10px; line-height: 20px;         
      }
      h4{
        font-size: 16px;
        color:rgb(207,30,33);
        font-weight: 600;
      }
    }
    .btns-container {
      width:200px;
    } 

  }
  .booking-details{
    .total{background:#f2f2f2;padding: 10px;
      margin: 2px;}
  }  
  .upsell-heading{padding:0.30rem 0.25rem!important;} 

.cart-widgetpage .MuiTypography-h1{font-size: 2rem !important;font-weight: 600 !important;line-height: 1.25 !important;}
.cart-widgetpage .MuiTypography-h5{font-size: 1.0625rem !important;font-weight: 500 !important; line-height: 1.25 !important;}
.cart-widgetpage .MuiTypography-body1{font-size: 13px !important;line-height: 25px !important;}

/* For Mobile View */
@media (max-width: 599.95px){
.cart-page{
  .MuiCardContent-root{padding: 0px;}
  table thead tr{background: #000; color:#fff;line-height: 25px;}
    table td{padding: 0px 5px;}
    table td:first-child{white-space: pre-wrap !important;}
  table.event-tbl, 
    table.upsell-tbl{
      border:none;
      font-size:12px;
    }
    table{
      &.event-tbl, &.upsell-tbl{
        td:first-child{
          min-width: 80px !important;
        }
        td:nth-child(2){min-width: 80px !important;}
        td:nth-child(2), td:nth-child(3), td:nth-child(4){white-space: nowrap !important;}
      }
    }
   
    table.upsell-tbl{
      margin-top: 0px !important;
       thead tr{background: #a7a7a7; color:#fff;line-height: 25px;}
    }
    a{
      &.btn-decrease, &.btn-increase, &.btn-trash{    
        padding: 0px !important;  
        svg{
      width: 15px;
      height: 15px;
     }
    }
  }
  .upsell-heading{ font-size: 12px;} 

}


.cart-item-breakdown {
  justify-content: space-between;
  
  .name-price {
    width: calc(100% - 436px);
    h6{
      font-size: 12px;           
    }  
    h4{
      font-size: 12px;
      color:rgb(207,30,33);
    }   
  }
  .total-price{
    // width:100px;
    text-align: right;      
    p{
      // margin-bottom: 0px;  
      font-size: 12px;      
    }
    h4{
      font-size: 12px;
      color:rgb(207,30,33);font-weight: 600;
    }
  }
  .btns-container {
    width:200px;padding-top: 8px !important;
  }

}
.cart-bdr{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 15px;padding: 10px;}
.booking-details{font-size: 12px;margin-bottom: 40px !important;}
}
